import React from "react";
import Layout from "../components/layout";
import { PageTitle } from "../components/Common/PageTitle";
import { JoinNewsLetterButton } from "../components/Common/NewsLetterPlug";
import ReactTooltip from "react-tooltip";

import aoyama from "../static/funstuff/aoyama.png";
import cozumel from "../static/funstuff/cozumel.png";
import lombok from "../static/funstuff/lombok.png";
import jade from "../static/funstuff/jade.png";

import { Section } from "../components/Layout/Section";

const stuffs = [
  {
    title: "Aoyama School of Jewelry and Metalsmithing",
    text: "I took a few classes to learn inlay. I would have loved to stay longer and learn more traditional Japanese techniques. Their collection of hammers and stakes is impressive!",
    url: "http://www.aokinschool.com/english.html",
    image: aoyama,
  },
  {
    title: "Cozumel Pearl Farm",
    text: "Tiny pearl farm on a tiny island. They’re just starting and have had their share of setbacks. Learning how they’re trying to set up the farm and doing things differently than they’re done elsewhere in the world is really interesting. And snorkeling over the actual pearl farm is very cool!",
    url: "https://www.cozumelpearlfarm.mx/ ",
    image: cozumel,
  },
  {
    title: "La Casa Del Jade",
    text: "Guatemala is a big jade country! You can choose your own piece of jade and with the help of Cesar you cut and polish a jade stone. ",
    url: "https://www.lacasadeljade.com/en/jade-workshop/",
    image: jade,
  },
  {
    title: "Autore Pearls",
    text: "I went to their showroom in Lombok. You can do a tour to learn about pearl farming and watch a pearl get extracted. They have a small shop where you can buy pearls and pearl jewellery. \n",
    url: "https://autorepearls.com.au/farming/",
    image: lombok,
  },
];

const CastingCompanies = () => {
  return (
    <Layout>
      <div className="relative pt-16 pb-6 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <PageTitle title="Fun Stuff" />
        </div>

        <div className="mt-12 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            If you’re anything like me, you find yourself pulled towards
            jewellery even on vacation! I always research beforehand if there’s
            a workshop or tour related to jewellery I can do. And I’ve found
            some good ones over the years! These are not sponsored/affiliates, I
            don’t get anything from recommending these places. I just loved my
            experience here and you might too!
          </p>
        </div>

        <nav className="h-full overflow-y-auto" aria-label="Directory">
          {stuffs.map((thing, idx) => (
            <Section
              item={{
                title: thing.title,
                text: thing.text,
                url: thing.url,
                image: thing.image,
                orientation: idx % 2 === 0 ? "right" : "left",
              }}
              titleLink
              key={thing.title}
            />
          ))}
        </nav>

        <div className="mt-24 prose prose-indigo prose-lg text-gray-700 mx-auto">
          <p>
            If you have a recommendation for a jewellery related tour or
            workshop let me know! I love learning new things, even on vacation!
          </p>
        </div>
      </div>

      <div className="mt-24">
        <JoinNewsLetterButton />
      </div>
      <ReactTooltip />
    </Layout>
  );
};

export default CastingCompanies;
